import Dropdownable from 'custom_elements/dropdownable'

class SuscriptionSelector extends Dropdownable {
  init () {
    this.mainClass = 'rnk-SuscriptionSelector'
  }

  connectedCallback () {
    super.connectedCallback()
    this.getDefault()
    this.setSelectListener()
    this.statusCheck()
  }

  getDefault () {
    this.setSelected(this.querySelector('[data-default]'))
  }

  setSelectListener () {
    const optionsSelector = this.getAttribute('options')
    const options = this.querySelectorAll(optionsSelector)
    options.forEach( (option) => {
      option.addEventListener('click', () => {
        if (!option.classList.contains('selected')) {
          this.setSelected(option)
        }
      })
    })
  }

  statusCheck () {
    // TODO: mejorar esta mierrrda que se hizo unicamente para las fichas en un nuevo PR y gestionarlo con eventos
    const notInForums = !document.location.pathname.startsWith('/foros')
    if (notInForums && this.hasAttribute('status') && document.querySelector('.rnk-Hero_Header')) {
      this.status = this.getAttribute('status')
      this.eventName = `suscription-${this.status}`
      window.suscriptionStatus = window.hasOwnProperty('suscriptionStatus') ? window.suscriptionStatus : this.eventName
      if (window.suscriptionStatus !== this.eventName) {
        document.body.style.cursor = 'wait'
        location.reload()
      }
    }
  }

  resetOptions () {
    this.querySelectorAll('.selected').forEach((item) => {
      item.classList.remove('selected')
    })
  }

  setSelected (option) {
    if (!option) { return }
    this.resetOptions()
    option.classList.add('selected')
  }
}

export default SuscriptionSelector
