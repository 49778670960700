class LazyloadBgimage extends HTMLDivElement {
  connectedCallback () {
    new IntersectionObserver(
      (entries, observer) => {
        if (entries.length > 0 && entries[0].intersectionRatio > 0) {
          this.onVisible(observer)
        }
      }, { rootMargin: '12px' }
    ).observe(this)
  }

  onVisible (observer) {
    this.style.backgroundImage = `url(${this.dataset.bgimage})`
    delete this.dataset.bgimage
    observer.unobserve(this)
  }
}

export default LazyloadBgimage
