class CustomCarousel extends HTMLElement {
  connectedCallback () {
    this.itemsWrapper = this.querySelector('c-carousel-items')
    this.items = this.querySelectorAll('c-carousel-item')
    this.arrowNext = this.querySelector('c-carousel-arrow-next')
    this.arrowPrev = this.querySelector('c-carousel-arrow-prev')
    this.reverseMode = false
    this.positionLast = this.items.length - 1
    this.moveTo(this.positionLast)

    this.arrowNext.addEventListener('click', () => {
      this.reverseMode = false
      clearInterval(this.interval)
      this.recalc()
    }, false)
    this.arrowPrev.addEventListener('click', () => {
      this.reverseMode = true
      clearInterval(this.interval)
      this.recalc()
    }, false)

    this.setInterval((this.dataset.interval || 0) * 1)
  }

  setInterval (seconds) {
    if (seconds > 0) {
      this.interval = setInterval(() => this.recalc(), seconds * 1000)
    }
  }

  recalc () {
    let nextPosition,
        currentPosition = parseInt(this.itemsWrapper.dataset.current)
    if (this.reverseMode) {
      nextPosition = (currentPosition === 0) ? this.positionLast : --currentPosition
    } else {
      nextPosition = (currentPosition === this.positionLast) ? 0 : ++currentPosition
    }
    this.moveTo(nextPosition)
  }

  moveTo (itemPosition = 0) {
    this.itemsWrapper.dataset.current = itemPosition
    this.sortItems()
    this.animate()
  }

  sortItems () {
    const current = this.itemsWrapper.dataset.current
    const center = Math.floor(this.items.length / 2)
    let ordinal
    this.items.forEach((item, position) => {
      if (position < current) {
        ordinal = this.items.length - (current - position)
      } else if (position > current) {
        ordinal = (position - current)
      } else {
        ordinal = 0
      }
      item.style.order = ordinal + 1

      this.styleItem(item, ordinal, center)
    })
  }

  styleItem(item, ordinal, center) {
    // Implementar en subclases si se quiere estilar los items
  }

  animate () {
    const inner = this.itemsWrapper
    this.reverseMode ? inner.classList.add('reverse') : inner.classList.remove('reverse')
    inner.classList.remove('animate')
    setTimeout(function () {
      inner.classList.add('animate')
    }, 50)
  }
}

export default CustomCarousel
