class TimelineSlider extends HTMLElement {
  connectedCallback () {
    if (this.getPairsAndCursor()) {
      this.setElements()
      this.setCursor(this.current, true)
    }
  }

  getPairsAndCursor () {
    if (this.hasAttribute('array')) {
      this.array = this.getAttribute('array')
      this.removeAttribute('array')
      this.array = JSON.parse(this.array)
      this.current = this.hasAttribute('cursor') ? this.getAttribute('cursor') : 0
    }
    return this.array.length > 1
  }

  setElements () {
    this.firstPair = this.array[0]
    this.lastPair = this.array[this.array.length - 1]

    this.first = document.createElement('timeline-first')
    this.first.innerHTML = `<a href="${this.firstPair[1]}">${formatDate(this.firstPair[0])}</a>`
    this.appendChild(this.first)

    this.buildRanger()

    this.last = document.createElement('timeline-last')
    this.last.innerHTML = `<a href="${this.lastPair[1]}">${formatDate(this.lastPair[0])}</a>`
    this.appendChild(this.last)
  }

  buildRanger () {
    this.ranger = document.createElement('timeline-ranger')
    this.cursor = document.createElement('timeline-cursor')
    this.input = document.createElement('input')

    this.input.type = 'range'
    this.input.min = 0
    this.input.max = this.array.length - 1
    this.input.addEventListener('input', () => { this.setCursor(this.input.value) })
    this.input.addEventListener('change', () => { this.setCursor(this.input.value) })

    this.ranger.appendChild(this.input)
    this.ranger.appendChild(this.cursor)
    this.appendChild(this.ranger)
  }

  setCursor (value, isInitial = false) {
    this.input.value = value
    const date = formatDate(this.array[value][0])
    const url = this.array[value][1]

    this.setAttribute('cursor', value)
    this.cursor.innerHTML = isInitial ? 'Escoge una fecha' : `<a href="${url}">${date} <br> Ver respuestas »</a>`
    this.cursor.style.left = `${value / this.input.max * 100}%`
  }
}

function formatDate(str){
  return new Date(str).toLocaleString('es-es', { year: 'numeric', month: 'short', day: 'numeric'})
}

export default TimelineSlider
