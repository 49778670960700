class PseudolinkLoader extends HTMLElement {
  connectedCallback () {
    this.url = this.getAttribute('url')
    this.method = this.getAttribute('method') || 'get'
    this.targetsSelector = this.getAttribute('target')
    this.targets = document.querySelectorAll(this.targetsSelector)

    this.onclick = this._doFetch

    this.shots = 'infinite'
    if (this.hasAttribute('shots')) {
      let shots = parseInt(this.getAttribute('shots'))
      this.removeAttribute('shots')
      this.shots = isNaN(shots) ? this.shots : shots
    }

    if (this.hasAttribute('autoclick')) {
      fetch(this.url, { method: this.method, credentials: 'same-origin' })
        .then(function (response) {
          return response.text()
        })
        .then((html) => {
          this._updateTarget(html)
        })

        if (this.targets.length === 1) {
          this.scrollIntoView()
        }
    }
  }

  static get observedAttributes () {
    return ['url']
  }

  attributeChangedCallback (attrName, oldVal, newVal) {
    if (attrName === 'url') {
      this.url = newVal
    }
  }

  _doFetch () {
    if (this.canFire && !this.classList.contains('pseudolink-loader--loading')) {
      this.classList.add('pseudolink-loader--loading')
      fetch(this.url, { method: this.method, credentials: 'same-origin' })
        .then(function (response) {
          return response.text()
        })
        .then((html) => {
          this._updateTarget(html)
          this.classList.remove('pseudolink-loader--loading')
          this._burnOne()
        })
    }
  }

  _updateTarget (html) {
    if (this.getAttribute('mode') === 'replace-pseudolink') {
      this._replaceTargetsPseudolinks(html)
    } else if (this.getAttribute('mode') === 'replace-children') {
      this._replaceTargetsChildren(html)
    } else { // default, could be named as 'replace-inner'
      this._replaceTargetsInner(html)
    }
  }

  _replaceTargetsPseudolinks (html) {
    const matchSelector = `${this.targetsSelector} pseudolink-loader`
    const oldPseudolinks = document.querySelectorAll(matchSelector)
    const newPseudolink = new DOMParser().parseFromString(html, 'text/html')
                          .body.querySelector(matchSelector)

    if (newPseudolink) oldPseudolinks.forEach(old => {
      old.replaceWith(newPseudolink.cloneNode(true))
    })
  }

  _replaceTargetsChildren (html) {
    const newNodes = new DOMParser().parseFromString(html, 'text/html')
                          .body.querySelector('*').children

    if (newNodes) this.targets.forEach(target => {
      target.replaceChildren(...newNodes)
    })
  }

  _replaceTargetsInner (html) {
    this.targets.forEach(target => target.innerHTML = html)
  }

  _burnOne () {
    if (!this.infiniteCartriges) {
      --this.shots
    }
  }

  get infiniteCartriges () {
    return this.shots === 'infinite'
  }

  get canFire () {
    return this.infiniteCartriges || this.shots > 0
  }
}

export default PseudolinkLoader
