class CkeditorEveryone extends HTMLTextAreaElement {
  connectedCallback () {
    const self = this
    const contenidoId = this.getAttribute('contenido_id')

    CKEDITOR.replace(self, {
      on: {
        instanceReady: function () {
          this.dataProcessor.writer.setRules('strong', {
            indent: true,
            breakBeforeOpen: true,
            breakAfterOpen: false,
            breakBeforeClose: false,
            breakAfterClose: false
          })
          this.dataProcessor.writer.setRules('a', {
            indent: true,
            breakBeforeOpen: true,
            breakAfterOpen: false,
            breakBeforeClose: false,
            breakAfterClose: false
          })
        }
      },
      allowedContent: this.isAdmin(),
      language: 'es',
      disableNativeSpellChecker: false,
      entities: false,
      height: (this.rows > 2 ? this.rows : 20) * 30,
      toolbar: [
        this.isAdmin() ?
          ['Bold', 'Italic', 'Blockquote', 'RemoveFormat'] :
          ['Bold', 'Italic', 'Blockquote'],
        ['Image', 'Link', 'Unlink'],
        ['Undo', 'Redo'],
        ['NumberedList', 'BulletedList'],
        ['Source']
      ],
      filebrowserImageUploadUrl: `/fotos/create_ckeditor?contenido_id=${contenidoId}`
    })

    CKEDITOR.on('dialogDefinition', function (ev) {
      if (ev.data.name === 'image') {
        var dialog = ev.data.definition
        var oldOnShow = dialog.onShow
        var infoTab = dialog.getContents('info')

        infoTab.remove('ratioLock')
        infoTab.remove('txtHeight')

        dialog.onShow = function () {
          oldOnShow.apply(this, arguments)
          this.selectPage('Upload')
        }
      }
    })
  }

  isAdmin () {
    return 'true' === this.getAttribute('admin')
  }
}

export default CkeditorEveryone
