class TableOfContents extends HTMLElement {
  connectedCallback () {
    this.dataset.container ||= 'body'
    this.dataset.selector ||= 'h2, h3'
    this.dataset.status = 'waiting'
    this.headings = this.container.querySelectorAll(this.dataset.selector)
    this.id = 'table-of-contents'
    this.minHeadings = parseInt(this.dataset.minheadings) || 4
    this.minWords = parseInt(this.dataset.minwords) || 600

    this.smallWindow = document.documentElement.scrollWidth < 1024
    this.className = this.smallWindow ? 'collapse' : ''

    if (this.unmetRequirements()) {
      this.dataset.status = this.debug ? 'warning' : 'empty'

      if (this.debug) {
        this.startNavList()
        this.collectAnchors()
      } else {
        this.temporaryPutAnchors()
      }

    } else {
      this.dataset.status = 'loading'
      this.startNavList()
      this.collectAnchors()
      this.dataset.status = 'loaded'
    }
  }

  slugifyText (text) {
    return text.replace(/ /g, '-').toLowerCase()
  }

  getAnchorFromNode (heading) {
    let cleanHeadingTitle = heading.textContent.trim()
    let anchor = document.createElement('A')
    anchor.textContent = cleanHeadingTitle
    anchor.dataset.heading = heading.tagName

    if (!this.previewMode) {
      heading.id ||= this.slugifyText(cleanHeadingTitle)
      anchor.href = `#${heading.id}`
    }

    return anchor
  }

  appendItemToList (content, list) {
    let listItem = document.createElement('LI')
    if (typeof content === 'string') {
      listItem.textContent = content
    } else {
      listItem.appendChild(content)
    }

    list ||= this.list
    list.appendChild(listItem)
  }

  temporaryPutAnchors () {
    this.validHeadings.forEach((heading) => {
      let cleanHeadingTitle = heading.textContent.trim()
      heading.id ||= this.slugifyText(cleanHeadingTitle)
    })
  }

  collectAnchors () {
    const headingTagNamesArray = this.headingTagNames.split(', ')
    let targetList = this.list

    this.validHeadings.forEach((heading, pos) => {
      let next = this.validHeadings[pos + 1]
      let anchor = this.getAnchorFromNode(heading)

      // si es el primer elemento en tratarse
      // si es el primer elemento en la jerarquía de cabeceras
      if (!this.list?.querySelectorAll('li') || headingTagNamesArray.indexOf(heading.tagName) === 0) {
        // apunta a la lista principal
        targetList = this.list
      }

      // añade el enlace a la lista (o sublista) objetivo
      this.appendItemToList(anchor, targetList)

      // si le sigue algún enlace
      if (next) {
        // si el elemento es hijo en la jerarquía
        if (headingTagNamesArray.indexOf(heading.tagName) < headingTagNamesArray.indexOf(next.tagName)) {
          // apuntar al padre del nodo actual para añadir un listado de hijos
          let childrenList = document.createElement('UL')
          childrenList.dataset.headings = next.tagName
          anchor.closest('li').appendChild(childrenList)
          targetList = childrenList
        }
        // si el elemento siguiente tiene más peso
        else if (headingTagNamesArray.indexOf(heading.tagName) > headingTagNamesArray.indexOf(next.tagName)) {
          // apunta a la lista de su peso más próxima, o a la lista principal
          targetList = anchor.closest(`ul[data-headings="${next.tagName}"]`) || this.list
        }
        // si el elemento es hermano en la jerarquía
        // mantener el puntero donde se ha añadido el nodo actual
      }
    })
  }

  switchCollapse = () => {
    if (!this.smallWindow) return

    const time = 300
    const newClassName = this.classList.contains('show') ? 'collapse' : 'collapse show'
    this.className = 'collapsing'
    setTimeout(() => {
      this.className = newClassName
    }, time)
  }

  startNavList () {
    this.titleTag = document.createElement('table-of-contents-title')
    this.titleTag.id = 'table-of-contents-title-tag'
    this.titleTag.textContent = 'Índice'
    this.titleTag.onclick = this.switchCollapse

    this.list = document.createElement('UL')
    this.nav = document.createElement('NAV')
    this.nav.setAttribute('aria-labelledby', 'table-of-contents-title-tag')
    this.nav.appendChild(this.list)

    this.appendChild(this.titleTag)
    this.appendChild(this.nav)
  }

  unmetRequirements () {
    let warnings = []

    if (this.currentWords != null && this.currentWords.length < this.minWords) {
      warnings.push(`⚠ ${this.currentWords.length}/${this.minWords} palabras`)
    }
    if (this.validHeadings.length < this.minHeadings) {
      warnings.push(`⚠ ${this.validHeadings.length}/${this.minHeadings} cabeceras`)
    }
    if (this.debug && warnings.length) {
      this.startNavList()
      this.titleTag.textContent = 'Contenido insuf.'
      warnings.forEach(warning => this.appendItemToList(warning, this.list))
    }

    return warnings.length > 0
  }

  get currentWords () {
    return this.container.textContent.match(/ /g)
  }

  get debug () {
    return this.hasAttribute('data-debug')
  }

  get headingTagNames () {
    return 'H2, H3, H4, H5, H6'
  }

  get validHeadings () {
    return Array.from(this.headings).filter(heading => heading.textContent.trim())
  }

  get container () {
    return document.querySelector(this.dataset.container)
  }

  get previewMode () {
    return this.dataset.preview === 'true'
  }
}

export default TableOfContents
