class ClassicIndex extends HTMLElement {
  constructor () {
    super()
    this.innerHTML = `
      <i class="rnk-Icon-spinner"></i>
      <span class="rnk-margin-left-medium">collecting anchors...</span>
    `
  }

  connectedCallback () {
    if (!this.dataset.selector) {
      this.innerHTML = 'El índice no pudo ser cargado.'
    } else {
      this.collectAnchors(this.dataset.selector)
    }
  }

  collectAnchors (selector) {
    var nodes = document.body.querySelectorAll(selector);
    if (nodes.length) {
      var list = document.createElement('UL')
      var toAnchor = this.dataset.anchor === 'true'
      var indexAnchor = document.createElement('DIV')
      if (toAnchor) {
        this.id = `index-${(new Date()).getTime()}`
        var label = '<small>Ir al índice</small>'
        indexAnchor.innerHTML = `<a href="#${this.id}">${label}</a>`
      }
      nodes.forEach((node) => {
        if (node.hasAttribute('id')) {
          var newAnchor = document.createElement('A')
          var newItem = document.createElement('LI')
          newItem.textContent = `${node.title || newAnchor.href}`
          newAnchor.href = `#${node.id}`
          newAnchor.appendChild(newItem)
          list.appendChild(newAnchor)
          if (toAnchor) {
            var heading = node.querySelectorAll('h1, h2, h3, h4, h5, h6')[0]
            heading = node.insertBefore(indexAnchor.cloneNode(true), heading)
          }
        }
      })
      this.innerHTML = ''
      this.appendChild(list)
    }
  }
}

export default ClassicIndex
