class AvatarWrapper extends HTMLElement {
  constructor () {
    super()
    const size = this.getAttribute('size')
    const typeCorrespondance = {
      grande      : 'grande',
      dia         : 'dia',
      cmedio      : 'comment', // Hackin' Verema
      ccoment     : 'comment',
      coment      : 'comment',
      comment     : 'comment',
      cthumbnail  : 'thumbnail',
      thumbnail   : 'thumbnail',
      mini        : 'mini'
    }

    var wrapper = this.querySelector('.rnk-AvatarWrapper')
    if (wrapper.className.indexOf('rnk-AvatarWrapper-') == -1) {
      wrapper.classList.add(`rnk-AvatarWrapper-${typeCorrespondance[size]}`)
    }
  }
}

export default AvatarWrapper
