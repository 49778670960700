class YoutubeIframe extends HTMLDivElement {
  connectedCallback () {
    this.calcVideoId()
    this.classList.add('vc-ImageBox')
    this.createIframe()
    this.createImg()
    this.addEventListener('click', this.onClick)
  }

  onClick () {
    const url = new URL(this.newIframe.src || this.newIframe.dataset.src)
    url.searchParams.append('autoplay', 1)
    this.newIframe.setAttribute('src', url)
    this.newIframe.setAttribute('autoplay', '')
    this.parentElement.replaceChild(this.newIframe, this)
  }

  createImg () {
    this.imageNode = document.createElement('img')
    this.imageNode.className = 'vc-ImageBox_Image'
    this.imageNode.src = this.jpgThumbnailSrc
    this.append(this.imageNode)
  }

  createIframe () {
    this.newIframe = document.createElement('iframe')

    Array.from(this.attributes).filter(attr => attr.name !== 'is').forEach(attr => {
      this.newIframe.setAttribute(`${attr.name}`, `${attr.value}`)
    })
    delete this.dataset.loading
  }

  calcVideoId () {
    // We need to do this because Cloudflare worker is converting src to data-src on iframe-handler
    const url = this.getAttribute('src') || this.dataset.src
    // https://www.youtube.com/embed/v_P0fH5ajAI?rel=0 should resolve to v_P0fH5ajAI
    // http://www.youtube.com/watch?v=yWJb1uQHsgo should resolve to yWJb1uQHsgo
    // https://www.youtube.com/watch?v=DNAHqi022tA&feature=youtu.be should resolve to DNAHqi022tA
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??(v=)?([^#&?]*).*/
    const match = url.match(regExp)
    this.videoId = (match && match[8].length === 11) ? match[8] : false
  }

  get jpgThumbnailSrc () {
    // use this statment until we'll connect with youtube API
    if (this.videoId === false || this.videoId === 'videoseries') {
      this.onClick()
    } else {
      return `https://i3.ytimg.com/vi/${this.videoId}/hqdefault.jpg`
    }
  }
}

export default YoutubeIframe
