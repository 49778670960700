import CustomCarousel from 'custom_elements/carousel'

class CoverCarousel extends CustomCarousel {
  styleItem(item, ordinal, center) {
    item.classList.remove('rnk-CarouselItem_center', 'rnk-CarouselItem_left', 'rnk-CarouselItem_right')
    if (ordinal < center ) {
      item.classList.add('rnk-CarouselItem_left')
    } else if (ordinal === center) {
      item.classList.add('rnk-CarouselItem_center')
    } else {
      item.classList.add('rnk-CarouselItem_right')
    }
  }
}

export default CoverCarousel
