class DialogModal extends HTMLElement {
  constructor () {
    super()

    this.openTimeout = 300
    this.closeTimeout = 100
    this.contentLoading = false
    this.nextPage = 1
    this._onEscapePressed = this._onEscapePressed.bind(this)
    this._onInfiniteScroll = this._onInfiniteScroll.bind(this)
    this._getSrcToFetch()
  }

  connectedCallback () {
    this._avoidDuplicating()
    this.closest('form') || this._moveToEndOfDocument()
    this._setActions()
  }

  attributeChangedCallback (attribute) {
    var actionDo = (action) => {
      var actions = this.querySelector('dialog-actions')
      var calling = actions.querySelector(`[action="${action}"]`)

      if (this.hasAttribute(action)) {
        calling = calling || document.createElement('dialog-action')
        calling.setAttribute('action', action)
        calling.textContent = (this.getAttribute(action))
        calling.parentNode || actions.appendChild(calling)
      } else {
        calling && calling.parentNode.removeChild(calling)
      }
    }

    var properties = {
      dismiss: () => actionDo('dismiss'),
      confirm: () => actionDo('confirm'),
      open: () => {
        this.hasAttribute('open') ? this._open() : this.close()
      },
      closing: () => {
        if (this.hasAttribute('closing')) this.close()
      },
      die: () => {
        if (this.hasAttribute('die')) this.destroy()
      },
      closingx: () => {
        var calling, closingx = this.querySelector('dialog-close')

        if (this.hasAttribute('closingx')) {
          calling = closingx || document.createElement('dialog-close')
          calling.setAttribute('action', 'dismiss')
          calling.parentNode || this.querySelector('dialog-header')
            .insertBefore(calling, this.querySelector('dialog-title'))
        } else {
          calling = closingx
          calling && calling.parentNode.removeChild(calling)
        }
      }
    }

    return properties.hasOwnProperty(attribute) ? properties[attribute]() : undefined
  }

  static get observedAttributes() {
    return ['open', 'confirm', 'dismiss', 'closingx', 'closing', 'die']
  }

  get dialogBody () {
    return this.querySelector('dialog-body')
  }

  get admitInfiniteScroll () {
    return this.hasSrcToFetch && this.hasAttribute('infinite-scroll')
  }

  get hasSrcToFetch () {
    return this.fetchSrc
  }

  open () {
    this.setAttribute('open', '')
  }

  _open () {
    this.style.animation = `fade-zoom-in ${this.openTimeout}ms ease-in`

    this._addEventListeners()
    this._lockBody()

    if (this.hasSrcToFetch && !this.beingFetchedYet) {
      this._fetchContent()
    }
  }

  destroy () {
    this.close(true)
  }

  close (removeAfterClose = false) {
    this.style.animation = `fade-zoom-out ${this.closeTimeout}ms ease-out`

    setTimeout(() => {
      this.removeAttribute('open')
      this.removeAttribute('closing')
      this._removeEventListeners()
      this._unlockBody()

      if (removeAfterClose) {
        this.parentNode.removeChild(this)
      }
    }, this.closeTimeout)
  }

  _lockBody () {
    document.body.style.overflow = 'hidden'
  }

  _unlockBody () {
    document.body.style.overflow = ''
  }

  _avoidDuplicating () {
    if (!this.id) return

    if (document.querySelectorAll('dialog-modal#' + this.id).length > 1) {
      this.parentNode.removeChild(this)
    }
  }

  _moveToEndOfDocument () {
    const estoyEnElDOM = this.isConnected
    const noEstoyAlFinalDelDocumento = this.parentNode !== document.body

    if (estoyEnElDOM && noEstoyAlFinalDelDocumento) {
      document.body.appendChild(this)
    }
  }

  _setActions (code) {
    if (this.hasAttribute('onconfirm')) {
      this._onConfirmCustomCode = this.getAttribute('onconfirm')
      this.removeAttribute('onconfirm')
    }

    if (this.hasAttribute('ondismiss')) {
      this._onDismissCustomCode = this.getAttribute('ondismiss')
      this.removeAttribute('ondismiss')
    }

    this.setAttribute('action', 'dismiss')
  }

  _addEventListeners () {
    this.addEventListener('click', this._onClick)
    document.addEventListener('keydown', this._onEscapePressed)

    if (this.admitInfiniteScroll) {
      this.dialogBody.addEventListener('scroll', this._onInfiniteScroll)
    }
  }

  _removeEventListeners () {
    this.removeEventListener('click', this._onClick)
    document.removeEventListener('keydown', this._onEscapePressed)

    if (this.admitInfiniteScroll) {
      this.dialogBody.removeEventListener('scroll', this._onInfiniteScroll)
    }
  }

  _onDismiss () {
    this._onDismissCustomCode ? eval(this._onDismissCustomCode) : this.close(!this.hasAttribute('persist'))
  }

  _onConfirm () {
    this._onConfirmCustomCode ? eval(this._onConfirmCustomCode) : this._onDismiss()
  }

  _onEscapePressed (event) {
    if (event.key === 'Escape') {
      this._onDismiss()
    }
  }

  _onInfiniteScroll (event) {
    if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
      this._fetchContent()
    }
  }

  _onClick (event) {
    event.stopPropagation()

    const targetIsDialogActionButton = event.target.tagName === 'dialog-action' || event.target.closest('dialog-action')
    const action = event.target.getAttribute('action')

    if (targetIsDialogActionButton && action === 'confirm') {
      this._onConfirm()
    } else if (action === 'dismiss') {
      this._onDismiss()
    }
  }

  _fetchContent () {
    if (this.contentLoading) return

    this.dialogBody.innerHTML += this._spinnerHTML()
    this.contentLoading = true
    this.beingFetchedYet = true

    fetch(this.admitInfiniteScroll ? this._buildPaginatedURL() : this.fetchSrc)
      .then(response => response.text())
      .then((html) => {
        this.dialogBody.removeChild(this.dialogBody.lastChild)
        this.dialogBody.innerHTML += html
        this.nextPage += 1
        this.contentLoading = false
      })
  }

  _getSrcToFetch () {
    if (this.hasAttribute('src')) {
      this.fetchSrc = this.getAttribute('src')
      this.removeAttribute('src')
    } else if (this.hasAttribute('encodedsrc')) {
      this.fetchSrc = atob(this.getAttribute('encodedsrc'))
      this.removeAttribute('encodedsrc')
    }
  }

  _buildPaginatedURL () {
    const url = new URL(this.fetchSrc)
    const conector = url.search ? '&' : '?'

    url.search += conector + `page=${this.nextPage}`

    return url
  }

  _spinnerHTML () {
    return '<div class="rnk-align-center rnk-margin-bottom-large"><i class="rnk-Icon-spinner-inline"></div>'
  }
}

export default DialogModal
