class LazyloadDiv extends HTMLElement {
  connectedCallback () {
    new IntersectionObserver(
      (entries, observer) => {
        if (entries.length > 0 && entries[0].intersectionRatio > 0) {
          this.onVisible(observer)
        }
      }, { rootMargin: '15px' }
    ).observe(this)
  }

  onVisible (observer) {
    const src = this.getAttribute('src')

    if (src) {
      fetch(src)
        .then((response) => response.text())
        .then((html) => this.innerHTML = html)
    }

    observer.unobserve(this)
  }
}

export default LazyloadDiv
