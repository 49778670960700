class ButtonRrss extends HTMLButtonElement {
  constructor () {
    super()
    this.type = 'button'
  }

  connectedCallback () {
    const url = this.getAttribute('target')
    this.addEventListener('click', function () {
      window.open(url, '_blank')
    })
  }
}

export default ButtonRrss
