class Dropdownable extends HTMLElement {
  constructor () {
    super()
    this.init()
  }

  init () {
    // TODO Rethink dependency on classes (should not depend on tags instead?)
    this.mainClass = ''
  }

  connectedCallback () {
    if (this.mainClass && !this.classList.contains(this.mainClass)) {
      this.classList.add(this.mainClass)
    }
    this.classList.remove('rnk-Icon-spinner')
    this.setToggleEvent()
    this.adjustRightCorner()
  }

  adjustRightCorner () {
    if (this.getAttribute('right-adjust') !== 'true') return

    const rect = this.getBoundingClientRect()
    const rightWindowGap = window.innerWidth - rect.right
    const dropdown = this.querySelector('dropdown-dropdown')
    if (rightWindowGap < dropdown.offsetWidth / 2) {
      dropdown.setAttribute('right', 'true')
    } else {
      dropdown.removeAttribute('right')
    }
  }

  toggleThis (target) {
    const isMe = (target === this)
    const isChild = (target.closest(this.tagName) === this)
    // soon we will check if it is a nested dropdown
    if (isMe || isChild) {
      this.classList.toggle('open')
      this.classList.contains('open') && this.scrollIntoView({ behavior: 'smooth' })
    } else {
      this.close()
    }
  }

  setToggleEvent () {
    document.body.addEventListener('click', (e) => this.toggleThis(e.target))
  }

  close () {
    this.classList.remove('open')
  }
}

export default Dropdownable
