class CountdownSpan extends HTMLElement {
  constructor () {
    super()
    const finish = new Date(this.getAttribute('finish')).getTime()
    setInterval(() => {
      this.textContent = this.format(finish)
    }, 1000)
  }

  format (finish) {
    const secondsRemaining = this.secondsRemaining(finish)
    if (secondsRemaining <= 0) return '00:00'
    const minutes = Math.floor(secondsRemaining / 60)
    const seconds = secondsRemaining % 60
    return `${this.withZeroLeft(minutes)}:${this.withZeroLeft(seconds)}`
  }

  secondsRemaining (finish) {
    const current = new Date().getTime()
    return Math.floor((finish - current) / 1000)
  }

  withZeroLeft (number) {
    if (number > 9) return String(number)
    return `0${String(number)}`
  }
}

export default CountdownSpan
