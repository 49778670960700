class CookieSetter extends HTMLElement {
  constructor () {
    super()
    const key = this.getAttribute('key')
    if (Cookies.get(key)) {
      this.remove()
    } else {
      const button = this.querySelector(this.getAttribute('button'))
      button.onclick = () => {
        const expiration = parseFloat(this.getAttribute('expires'))
        Cookies.set(key, 1, { expires: expiration })
        this.remove()
      }
    }
  }
}

export default CookieSetter
