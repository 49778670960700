import Dropdownable from 'custom_elements/dropdownable'

class NotificationBell extends Dropdownable {
  constructor () {
    super()
    this.navbarParent = this.closest('.rnk-MainSiteNav')
    this.bell = this.querySelector('dropdown-toggle')
    this.unreadBadge = this.bell.querySelector('notification-bell-unread-badge')
    this.dropdownBox = this.querySelector('dropdown-dropdown')
    this.list = this.dropdownBox.querySelector('ul')

    window.addEventListener('resize', this.resizeDropdownBox.bind(this))

    this.resizeDropdownBox()
  }

  resizeDropdownBox () {
    const newHeight = window.innerHeight - this.navbarParent.offsetHeight
    this.dropdownBox.style.maxHeight = newHeight + 'px'
    this.adjustRightCorner()
  }

  toggleThis (target) {
    const isBell = (target.closest('DROPDOWN-TOGGLE') === this.bell)
    const isNotChild = (target.closest(this.tagName) !== this)
    const isClosingTrigger = target.matches('notification-bell-close-trigger, notification-bell-close-trigger *')

    if (isBell) { // apply ad-hoc
      this.classList.contains('open') ? this.close() : this.open()
    } else if (isNotChild || isClosingTrigger) {
      this.close()
    }
  }

  open () {
    this.requestItems()
    this.classList.add('open')
    this.markAsNotified()
  }

  markAsNotified () {
    if (this.unreadBadge) {
      fetch('/notificaciones/marcar-todas-notificadas/')
        .then(response => { if (response.ok) this.unreadBadge.remove() })
    }
  }

  requestItems () {
    if (this.listItemsToBeLoaded) {
      fetch('/ajax/notificaciones/listado-campana/')
        .then(response => {
          if (response.ok) {
            response.text().then(html => {
              this.list.innerHTML = html
              delete this.list.closest('[data-loading]').dataset.loading
            })
          }
        })
    }
  }

  get listItemsToBeLoaded () {
    return this.list.querySelectorAll('li').length === 0
  }
}

export default NotificationBell
