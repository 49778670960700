import CookieSetter from 'custom_elements/cookie-setter'
import PseudolinkLoader from 'custom_elements/pseudolink-loader'
import CountdownSpan from 'custom_elements/countdown-span'
import AvatarWrapper from 'custom_elements/avatar-wrapper'
import ClassicIndex from 'custom_elements/classic-index'
import Dropdownable from 'custom_elements/dropdownable'
import SuscriptionSelector from 'custom_elements/suscription-selector'
import ProfileAgent from 'custom_elements/profile-agent'
import NotificationBell from 'custom_elements/notification-bell'
import DialogModal from 'custom_elements/dialog-modal'
import CkeditorEveryone from 'custom_elements/ckeditor-everyone'
import CustomCarousel from 'custom_elements/carousel'
import EmergiaTooltip from 'custom_elements/emergia-tooltip'
import PhoneWithPrefix from 'custom_elements/phone-with-prefix'
import LazyloadBgimage from 'custom_elements/lazyload-bgimage'
import LazyloadTemplate from 'custom_elements/lazyload-template'
import TimelineSlider from 'custom_elements/timeline-slider'
import CoverCarousel from 'custom_elements/cover-carousel'
import TableOfContents from 'custom_elements/table-of-contents'
import ButtonRrss from 'custom_elements/button-rrss'
import LazyloadDiv from 'custom_elements/lazyload-div'
import YoutubeIframe from 'custom_elements/youtube-iframe'

customElements.define('cookie-setter', CookieSetter)
customElements.define('pseudolink-loader', PseudolinkLoader)
customElements.define('countdown-span', CountdownSpan)
customElements.define('avatar-wrapper', AvatarWrapper)
customElements.define('classic-index', ClassicIndex)
customElements.define('dropdown-able', Dropdownable)
customElements.define('suscription-selector', SuscriptionSelector)
customElements.define('profile-agent', ProfileAgent)
customElements.define('notification-bell', NotificationBell)
customElements.define('dialog-modal', DialogModal)
customElements.define('ckeditor-everyone', CkeditorEveryone, { extends: 'textarea' })
customElements.define('c-carousel', CustomCarousel)
customElements.define('emergia-tooltip', EmergiaTooltip)
customElements.define('phone-with-prefix', PhoneWithPrefix)
customElements.define('lazyload-bgimage', LazyloadBgimage, { extends: 'div' })
customElements.define('lazyload-template', LazyloadTemplate, { extends: 'template' })
customElements.define('timeline-slider', TimelineSlider)
customElements.define('cover-carousel', CoverCarousel)
customElements.define('table-of-contents', TableOfContents)
customElements.define('button-rrss', ButtonRrss, { extends: 'button' })
customElements.define('lazyload-div', LazyloadDiv)
customElements.define('youtube-iframe', YoutubeIframe, { extends: 'div' })
